// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-page-js": () => import("./../src/pages/aboutPage.js" /* webpackChunkName: "component---src-pages-about-page-js" */),
  "component---src-pages-career-detail-js": () => import("./../src/pages/career-detail.js" /* webpackChunkName: "component---src-pages-career-detail-js" */),
  "component---src-pages-career-fs-detail-js": () => import("./../src/pages/career-fs-detail.js" /* webpackChunkName: "component---src-pages-career-fs-detail-js" */),
  "component---src-pages-career-js-detail-js": () => import("./../src/pages/career-js-detail.js" /* webpackChunkName: "component---src-pages-career-js-detail-js" */),
  "component---src-pages-career-page-js": () => import("./../src/pages/careerPage.js" /* webpackChunkName: "component---src-pages-career-page-js" */),
  "component---src-pages-clients-page-js": () => import("./../src/pages/clientsPage.js" /* webpackChunkName: "component---src-pages-clients-page-js" */),
  "component---src-pages-contact-page-js": () => import("./../src/pages/contactPage.js" /* webpackChunkName: "component---src-pages-contact-page-js" */),
  "component---src-pages-cookie-js": () => import("./../src/pages/cookie.js" /* webpackChunkName: "component---src-pages-cookie-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iys-js": () => import("./../src/pages/iys.js" /* webpackChunkName: "component---src-pages-iys-js" */),
  "component---src-pages-platforms-content-management-and-application-platform-js": () => import("./../src/pages/platforms/content-management-and-application-platform.js" /* webpackChunkName: "component---src-pages-platforms-content-management-and-application-platform-js" */),
  "component---src-pages-platforms-customer-data-and-marketing-platform-js": () => import("./../src/pages/platforms/customer-data-and-marketing-platform.js" /* webpackChunkName: "component---src-pages-platforms-customer-data-and-marketing-platform-js" */),
  "component---src-pages-platforms-fm-js": () => import("./../src/pages/platforms/fm.js" /* webpackChunkName: "component---src-pages-platforms-fm-js" */),
  "component---src-pages-platforms-page-js": () => import("./../src/pages/platformsPage.js" /* webpackChunkName: "component---src-pages-platforms-page-js" */),
  "component---src-pages-platforms-venuex-js": () => import("./../src/pages/platforms/venuex.js" /* webpackChunkName: "component---src-pages-platforms-venuex-js" */),
  "component---src-pages-support-page-js": () => import("./../src/pages/supportPage.js" /* webpackChunkName: "component---src-pages-support-page-js" */)
}

