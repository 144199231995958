/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/assets/css/bootstrap.min.css'
import './src/assets/css/normalize.css'
import './src/assets/css/kns.css'
import './src/assets/css/resp.css'
import './src/assets/css/fullpage.css'